import React from 'react'
import Seo from "../../components/seo/index";
import Layout from "./layout/index";
import POCRegTable from './components/poc-reg-table'

const POCDetails = () => {
  return (
    <div>
    <Seo title="Green Heartfulness Run 2024" />
    <Layout isSticky>
     <POCRegTable />
    </Layout>
  </div>
  )
}

export default POCDetails;